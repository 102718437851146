import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, apiError } from './actions';

import { loginUser as apiLoginUser } from '../../../helpers/api';
import { AUTH_TOKEN } from '../../../constants';

function* loginUser({ payload: { user, history, recaptcha } }) {
    try {
        const response = yield call(apiLoginUser, user.email, user.password, user.code, recaptcha);

        if (response && response.expired) {
            history.push(`/reset-password?k=${response.resetPasswordKey}`);
        } else {
            yield put(loginSuccess(response));
            
            localStorage.setItem(AUTH_TOKEN, response.token)
            if(user && user.role === 'CLIENT')
                history.push('/dashboard');
            else
                history.push('/client-info');
        }
    } catch (error) {
        console.log(error)
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem(AUTH_TOKEN);
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;