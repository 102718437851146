import React, { useEffect, useState } from 'react';

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import logo from "../../assets/images/logo.png";
import { checkResetKey, updateUserPassword } from '../../helpers/api';

import Copyright from '../../components/Common/Copyright'

import { strongRegex } from '../../constants'

const ResetPassword = ({ history }) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [context, setContext] = useState('')
    const [formError] = useState('')

    const [uid, setUid] = useState('')

    const checkReset = async (k) => {
        let result = await checkResetKey(k)
        if (result.status === 'ERROR') {
            setError(result.error || 'UNKNOWN')
        } else {
            setContext(result.context)
            setUid(k)
        }

        setLoading(false)
    }

    useEffect(() => {
        let search = history.location.search;
        let params = new URLSearchParams(search);
        let k = params.get('k');

        checkReset(k)
    })

    const handleValidSubmit = async (event, values) => {
        setLoading(true)
        
        let res = await updateUserPassword(uid, values.password)
        if (res.status === 'SUCCESS') {
            history.push('/login')
        } else {
            setError(res.error||'UNKNOWN')
        }

        setLoading(false)
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="wcard-lugh">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Reset Password</h5>
                                                <p className="text-white">Set new password</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={logo} alt="" className="rounded-circle" height="52" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        {loading && (
                                            <div style={{ textAlign: 'center' }}>
                                                <div className="spinner-grow text-primary m-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )}

                                        {!loading && error !== '' && (
                                            <Alert color="danger">{error.toString()}</Alert>
                                        )}

                                        {!loading && (error === '' || context === 'EXPIRED') && (
                                            <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
                                                {formError && formError ? <Alert color="danger">{formError.toString()}</Alert> : null}
                                                {context && context === 'EXPIRED' ? <Alert color="info">Your password has expired, please choose a new one.</Alert> : null}

                                                <div className="form-group">
                                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" validate={{
                                                        required: { value: true, errorMessage: 'Please enter a password' },
                                                        pattern: { value: strongRegex, errorMessage: 'Your password must be at least 8 characters, 1 upper case, 1 lower case, 1 number and 1 special character.' },
                                                    }} />
                                                </div>
                                                <div className="form-group">
                                                    <AvField name="confirmpassword" label="Password" value="" type="password" required placeholder="Confirm Password" validate={{ match: { value: 'password', errorMessage: 'Passwords are not matching' } }} />
                                                </div>

                                                <div className="mt-3">
                                                    <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Set password</button>
                                                </div>
                                            </AvForm>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <Copyright />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, null)(ResetPassword));

