import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

 // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Request - Client side
import Purchase from "../pages/Requests/Purchase";
import Sale from "../pages/Requests/Sale";
import Listing from "../pages/Requests/Listing";
import ClientInfo from "../pages/Transactions/ClientInfo";

const authProtectedRoutes = [

	// Client action paths
	{ path: "/request/purchase", exact: true, component: Purchase, action: "client:requests"},
	{ path: "/request/sale", exact: true, component: Sale, action: "client:requests"},
	{ path: "/request/listing", exact: true, component: Listing, action: "client:requests"},
	{ path: "/client-info", exact: true, component: ClientInfo, action: "client:requests"},

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/client-info" /> },
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/reset-password", component: ResetPassword },
	{ path: "/forgot-password", component: ForgetPwd },

	// Authentication Inner
	{ path: "/pages-login", component: Login1 },
	{ path: "/pages-forgot-pwd", component: ForgetPwd1 },
	{ path: "/auth-lock-screen", component: LockScreen }
];

export { authProtectedRoutes, publicRoutes };
