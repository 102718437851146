import axios from 'axios'
import * as rdd from 'react-device-detect'
import { AUTH_TOKEN, API_URL } from '../constants'

const scApi = axios.create({
  baseURL: API_URL,
  timeout: 120000,
  headers: {},
});

// Add a request interceptor
scApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token || ''}`;
  }
  return config;
});

const call = async (method, path, data) => {
  try {
    return (await scApi[method.toLowerCase()](path, data)).data
  } catch(e) {
    console.error(e)
  }

  return { status: 'ERROR' }
}

//Login a user using email password and 2FA code with Google Authenticator
export const loginUser = async (email, password, code, recaptcha) => {
  const device = {osName: rdd.osName, osVersion: rdd.osVersion, browserName: rdd.browserName}
  console.log(device)
  let res = await call("POST", '/auth/login', { email, password, code, device, recaptcha })
  if (res.status === 'SUCCESS') {
    if(res.user && !res.user.client)
      throw new Error(JSON.stringify({
        err: 'WRONG_PF',
        remaining: null
      }))
    else  
      return res
  } else {
    throw new Error(JSON.stringify({
      err: res.error || res.message || 'UNKNOWN',
      remaining: res.remaining || null
    }))
  }
}

//Get current user
export const getUser = async () => {
  let res = await call("GET", '/client/check')
  if (res.status === 'SUCCESS') {
    return res
  } else {
    return {
      status: 'ERROR'
    }
  }
}

//Update user's password
export const updateUserPassword = async (k, password) => {
  return call("PUT", `/auth/${k}/password`, { password })
}

//Authorize reset password
export const getResetPassword = async (email) => {
  return call("POST", `/auth/reset`, { email })
}

//Validate reset key
export const checkResetKey = async (k) => {
  return call("GET", `/auth/check-reset?k=${k}`)
}

//TODO: Reset password
export const forgetPassword = async (email) => {}

// CLIENTS

          //Fetch requests for current client
          export const clientRequests = async () => {
            return call("GET", '/client/requests')
          }

          //Fetch given request
          export const fetchRequest = async (reference) => {
            return call("GET", `/client/request/${reference}`)
          }

          //Get all wallet info for client
          export const clientProfile = async () => {
            return call("GET", '/client/profile')
          }

          //Get pending requests
          export const pendingRequests = async () => {
            return call("GET", '/client/pending/requests')
          }

          //Cancel request
          export const clientCancelRequest = async (reference) => {
            return call("DELETE", `/client/cancel/${reference}`)
          }

          //Register new request
          export const clientSaveRequest = async (reference, amount, description, iban, action, publicKeyHash, chain) => {
            let res = await call("POST", '/client/request/save', { reference, amount, description, iban, action, publicKeyHash, chain })
            if (res.status === 'SUCCESS') {
              return res
            } else {
              throw new Error(res.error || 'UNKNOWN')
            }
          }

          //Get client's idbanks
          export const clientIdbanks = async () => {
            return call("GET", `/client/idbanks`)
          }

          // GET Clients /client/fee/:publicKeyHash
          export const feeClient = async () => {
            return call("GET", `/client/fee`)
          }

          // GET Base fee
          export const getBaseFee = async () => {
            return call("GET", `/client/basefee`)
          }


