import React from 'react'

import {CSVLink} from 'react-csv'
import moment from 'moment-timezone'
import { Button } from "reactstrap"

const CSV = ({ transactions, csvRows, name }) => {
  return (
    <div className="row" style={{ justifyContent: 'flex-end' }}>
      <Button color="primary" style={ { border: 'none', color: 'white' } }
          role="button">
          <CSVLink className="btn btn-primary btn-sm" id="csvLink" style={ {
              border: 'none',
              color: 'inherit',
              padding: '0px',
              font: 'inherit',
              outline: 'inherit',
              zIndex: 'inherit',
              boxShadow: 'none'
          } } onClick={ ( e ) => {
              e.stopPropagation();
          } }
              enclosingCharacter={`"`}
              separator={";"}
              filename={ name + '_' + moment().format( 'YYYY_MM_DD_HH_mm_ss' ) + '.csv' }
              headers={ transactions.columns.map(c => {
                  return {
                      label: c.label,
                      key: c.field
                  }
              }) } data={ csvRows }>
              &nbsp;Export CSV report
          </CSVLink>
      </Button>
    </div>
  )
}

export default CSV