import React, { Component } from 'react';

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';

// import images
import logo from "../../assets/images/logo.png";

import Copyright from '../../components/Common/Copyright'

import {RECAPTCHA_KEY} from '../../constants'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {value: null, expired: false, key: 0}
        this._reCaptchaRef = React.createRef();

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        if(this.state.value){
            this.props.loginUser(values, this.props.history, this.state.value);
            this.setState({key: this.state.key + 1})
        }
    }

    handleChange = value => {
      console.log("Captcha value:", value);
      this.setState({ value });
      // if value is null recaptcha expired
      if (value === null) this.setState({ expired: "true" });
    };

    componentDidMount = async() => {
        this.props.apiError("");
    }

    render() {
        const error = this.props.error ? JSON.parse(this.props.error.message) : null
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="wcard-lugh">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome!</h5>
                                                    <p className="text-white">Sign in to continue.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-5">
                                        <div>
                                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                                {error && error.err && ((error.err === 'LOCKED' && !error.remaining) || (error.err === 'NOT_FOUND' && !error.remaining)) ? <Alert color="danger">{error.err.toString()}</Alert> : null}
                                                {error && error.remaining && error.err === 'NOT_FOUND' ? <Alert color="danger">{`${error.remaining} remaining tries before account is locked for 30 minutes`}</Alert> : null}
                                                {error && error.remaining && error.err === 'LOCKED' ? <Alert color="danger">{`Account is locked for ${parseInt(error.remaining/1000/60)} minutes`}</Alert> : null}
                                                {error && error.err === 'WRONG_PF' ? <Alert color="danger">If your are a Lugh operator please sign in to <b>lugh.sceme.io</b></Alert> : null}

                                                <div className="form-group">
                                                    <AvField name="email" label="Email" value="" className="form-control" placeholder="Enter email" type="email" required />
                                                </div>

                                                <div className="form-group">
                                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                                </div>

                                                <div className="form-group">
                                                    <AvField name="code" label="OTP" value="" type="text" required placeholder="Enter OTP code" validate={{
                                                        required: { value: true, errorMessage: 'The OTP code is required' },
                                                        pattern: { value: '^[0-9]{6}$', errorMessage: 'OTP code should be 6 digits' },
                                                    }} />
                                                </div>

                                                <div className="form-group">
                                                    <ReCAPTCHA
                                                        key={this.state.key}
                                                        style={{ display: "inline-block" }}
                                                        ref={this._reCaptchaRef}
                                                        sitekey={RECAPTCHA_KEY}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>

                                                <div className="mt-3">
                                                    <button className="btn btn-primary btn-block waves-effect waves-light" type="submit" disabled={!this.state.value}>
                                                        Log In
                                                    </button>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <Copyright />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Login));

