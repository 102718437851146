import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media, Button, Label } from "reactstrap";
import Loader from 'react-loader-spinner';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

//i18n
import SVGLogo from '../../components/Common/SVGLogo.js';
import TezosLogo from '../../components/Common/TezosLogo.js';
import EthereumLogo from '../../components/Common/EthereumLogo.js';
import PolygonLogo from "../../components/Common/PolygonLogo";
import Idbank from './components/Idbank';
import Fees from './components/Fees';
import { clientProfile } from '../../helpers/api';
import CredentialsModal from "./Modals/CredentialsModal.js";

class ClientInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: {},
            wallets: [],
            idbanks: [],
            credentialsModal: false,
            loading: true,
            emailModal: false,
            nameModal: false,
            vatNumberModal: false,
            ipAddressModal: false,
            walletModal: false,
            displayIban: false,
            volumes: {},
            oca: {},
            feeModel: null,
            reserveTezos: null,
            reserveEthereum: null,
            tokenTezos: null,
            tokenEthereum: null,
            tokenPolygon: null,
        };
    }

    toggleCredentialsModal = () => {
        this.setState({ credentialsModal:  !this.state.credentialsModal})
    }

    loadClient = async() => {
        this.setState({loading: true})
        const res = await clientProfile()
        this.setState({client: res.data.client, wallets: res.data.wallets, idbanks: res.data.idbanks, feeModel: res.data.feeModel,
                       volumes: res.data.volumes, oca: res.data.oca, tokenTezos: res.data.fa2, tokenEthereum: res.data.erc20, 
                       tokenPolygon: res.data.erc20Polygon, loading: false})
    }

    componentDidMount = () => {
        this.loadClient()
    }

    render() {
        return (
            <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            {this.state.loading?
                                <Loader type="Puff" color="#545454" height={50} width={50}/>
                            :
                                <Row>
                                    <Col xl="4">
                                        <Card className="overflow-hidden">
                                            <div className="wcard-client p-2">
                                                {this.state.client.logo && <img style={{display: "block", maxWidth: "200px", maxHeight: "120px"}}
                                                    src={`https://premiere.infura-ipfs.io/ipfs/${this.state.client.logo}`} 
                                                    alt="logo"/>}
                                            </div>
                                            <CardBody className="pt-2">
                                                <Row>
                                                    <Col sm="6">
                                                        <h5 className="font-size-15 text-truncate mt-4 p-relative">
                                                            {this.state.client.name}
                                                        </h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">
                                                            {this.state.client.vatNumber? this.state.client.vatNumber : 'No VAT number provided'}
                                                        </p>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="pt-4">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <h5 className="font-size-15">Email</h5>
                                                                    <p className="text-muted mb-0 font-size-11 p-relative">
                                                                        {this.state.client.email}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    <Col sm="12">
                                                        <h5 className="font-size-15 text-truncate mt-4">Tezos FA2 Contract</h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">   
                                                            <small>{this.state.tokenTezos}</small>
                                                        </p>
                                                    </Col>

                                                    <Col sm="12">
                                                        <h5 className="font-size-15 text-truncate mt-4">Ethereum ERC20 Contract</h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">   
                                                            <small>{this.state.tokenEthereum}</small>
                                                        </p>
                                                    </Col>

                                                    <Col sm="12">
                                                        <h5 className="font-size-15 text-truncate mt-4">Polygon ERC20 Contract</h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">   
                                                            <small>{this.state.tokenPolygon}</small>
                                                        </p>
                                                    </Col>

                                                    <Col sm="12">
                                                        <h5 className="font-size-15 text-truncate mt-4">Lugh IBAN (where to send funds)</h5>
                                                        <p className="text-muted mb-0 text-truncate p-relative">   
                                                            <small>{this.state.client.receivingBank}</small>
                                                        </p>
                                                    </Col>

                                                    <Col sm="6">
                                                        <div className="pt-4">
                                                            <Row>
                                                                <Col xs="12">
                                                                    <p className="text-muted mb-0 font-size-11 mb-2">Display API credentials</p>
                                                                    <Button type="button" color="info" onClick={this.toggleCredentialsModal}>API credentials</Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        {this.state.wallets.map(wallet =>
                                            <Card className="mini-stats-wid" key={`w-${wallet.publicKeyHash}`}>
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <p className="text-muted font-weight-medium">
                                                                {wallet.chain === 'ethereum'? 'Ethereum ERC20': wallet.chain === 'polygon'? 'Polygon ERC20' : 'Tezos FA2'} Balance
                                                                <br/>
                                                                <strong>
                                                                    {wallet.name}
                                                                </strong>
                                                                <br/>
                                                                <small>
                                                                    {wallet.publicKeyHash}
                                                                </small>
                                                            </p>
                                                            <h4 className="mb-0">
                                                                <SVGLogo/> {wallet.balance.toLocaleString()}
                                                            </h4>
                                                        </Media>
                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title" style={{paddingLeft: "6px"}}>
                                                                {wallet.chain === 'ethereum'?
                                                                        <EthereumLogo width="20" height="20" />
                                                                    :
                                                                    wallet.chain === 'polygon'?
                                                                        <PolygonLogo width="20" height="20" />
                                                                    :
                                                                        <TezosLogo width="20" height="20" />
                                                                }
                                                            </span>
                                                        </div>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Col>
                                    <Col xl="8">
                                        <Row>
                                            <Col md="6">
                                                <Card className="mini-stats-wid">
                                                    <CardBody>
                                                        <Media>
                                                            <Media body>
                                                                <h4>
                                                                    Purchase / sale volumes
                                                                </h4>
                                                                <hr/>
                                                                {Object.keys(this.state.volumes).map((key , i) => 
                                                                    <div key={`v_${i}`}>
                                                                        <Label className="mb-0">{key}</Label>
                                                                        <p className="mt-0">
                                                                            Volume: <SVGLogo width="14" height="14"/>{this.state.volumes[key].volume.toLocaleString()} 
                                                                            <small className="ml-2">(Mint: {this.state.volumes[key].mint.toLocaleString()} | Burn: {this.state.volumes[key].burn.toLocaleString()})</small>
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </Media>
                                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-dark align-self-top">
                                                                <span className="avatar-title bg-dark">
                                                                    <i className="bx bx-chart font-size-24"></i>
                                                                </span>
                                                            </div>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                                {this.state.oca && this.state.oca.Total.volume > 0 &&
                                                    <Card className="mini-stats-wid">
                                                        <CardBody>
                                                            <Media>
                                                                <Media body>
                                                                    <h4>
                                                                        OCA
                                                                    </h4>
                                                                    <hr/>
                                                                    {Object.keys(this.state.oca).map((key , i) => 
                                                                        <div key={`oca_${i}`}>
                                                                            <Label className="mb-0">{key}</Label>
                                                                            <p className="mt-0">
                                                                                Volume: <SVGLogo width="14" height="14"/>{this.state.oca[key].volume.toLocaleString()} 
                                                                                <small className="ml-2">(Euro: {this.state.oca[key].euro.toLocaleString()} | Crypto: {this.state.oca[key].crypto.toLocaleString()})</small>
                                                                                <br/>
                                                                                Fees: <SVGLogo width="14" height="14"/>{this.state.oca[key].fees.toLocaleString()}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </Media>
                                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-dark align-self-top">
                                                                    <span className="avatar-title bg-dark">
                                                                        <i className="bx bx-trending-up font-size-24"></i>
                                                                    </span>
                                                                </div>
                                                            </Media>
                                                        </CardBody>
                                                    </Card>
                                                }
                                            </Col>
                                            <Col md="6">
                                                <Idbank client={this.state.client} idbanks={this.state.idbanks} loadClient={this.loadClient} 
                                                        displayIban={this.state.displayIban}/>
                                                {this.state.feeModel && <Fees feeModel={this.state.feeModel}/>}
                                                
                                            </Col>
                                        </Row>
                                    </Col>
                                    <CredentialsModal credentialsModal={this.state.credentialsModal}
                                            toggleCredentialsModal={this.toggleCredentialsModal}
                                            client={this.state.client}
                                            loadClient={this.loadClient} />
                                </Row>
                            }
                        </Container>
                    </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default withRouter(connect(mapStatetoProps, null)(withTranslation()(ClientInfo)));
