import React, { Component } from "react";
import { Row, Col, Card, CardBody, Label } from "reactstrap";

class Idbank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iban: null,
            bic: null,
        };
    }

    render() {
        return (
            <Card className="mini-stats-wid">
                <CardBody>
                <Label>Saved IBANs</Label>
                {this.state.error && <span className="badge badge-danger font-size-10">{this.state.error}</span>}
                <Row>
                    {this.props.idbanks && this.props.idbanks.map(idbank =>
                            <Col xl="12" sm="12" key={idbank.iban}>
                                <div className="mb-3">
                                    <label className="card-radio-label mb-2" onClick={() => {this.setState({selectedIban: idbank.iban})}}>
                                            <div className="card p-2">
                                                <div>
                                                    <span className="text-muted font-size-12 mb-1">{idbank.iban}</span>
                                                    <h5>{idbank.bic}</h5>
                                                </div>
                                            </div>
                                    </label>
                                </div>
                            </Col>
                    )}
                </Row>
                </CardBody>  
            </Card>          
                                            
        );
    }
}
  
export default Idbank;
